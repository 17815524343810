import React from 'react'
import popuptickicon from '../../Assets/popuptickicon.png'
export default function SuccessPopup({handleclose}) {
    return (
        <>
            <div className="modal d-block successpopup" tabindex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>handleclose()}></button>
                        </div>
                        <div className="modal-body">
                            <div className="imgs text-center">
                                <img src={popuptickicon} width={'20%'} alt="tick_icon" />
                                <h5 className='mt-4'>Well Done</h5>
                                <p>your message sent successfully</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
