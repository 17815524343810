import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './components/Pagecomponent/HomePage';
import Aboutus from './components/Pages/AboutUsPage';
import FAQ from './components/Pages/FAQPage';
import Contactus from './components/Pages/ContactUsPage';
import Error from './components/Pagecomponent/ErrorPage';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route index element={<Homepage />}></Route>
          <Route path={'/aboutus'} element={<Aboutus />}></Route>
          <Route path={'/FAQ'} element={<FAQ />}></Route>
          <Route path={'/contactus'} element={<Contactus />}></Route>
          <Route path={'/error404'} element={<Error />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
