import React from 'react'
import '../../css/HomePage.scss';
import { Link, NavLink } from 'react-router-dom'
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import aboutusimg from '../../Assets/aboutuspage-img.png'
export default function AboutUsPage() {
    return (
        <>
            <Navbar />
            <div className="Aboutus-page">
                <div className="container">
                    <div className="row sectionheading">
                        <h3>About Us</h3>
                        <h6 className='text-center color-grey'>Empower your journey towards sustainable mobility with our innovative financing and leasing options tailored specifically for electric vehicle owners.</h6>
                        <div className='text-center mt-5'>
                            <img src={aboutusimg} width={'40%'} alt="about_img" />
                        </div>
                        <h6 className='text-center color-grey mt-5'>
                            At CredGreen, we understand the importance of accessible and affordable financing in accelerating the adoption of electric vehicles. <br></br>Whether you're a private individual, a fleet manager, or a business owner, we offer flexible and competitive financing packages designed to meet your unique needs. Our team of experts is dedicated to providing personalised assistance every step of the way, ensuring a seamless and hassle-free experience.
                            <br></br>With our comprehensive financing solutions, you can say goodbye to the upfront costs of purchasing an EV battery. Instead, enjoy the convenience of affordable monthly payments while benefiting from the latest in EV technology.<br></br>Experience the future of transportation today with CredGreen. Explore our range of financing options and take the first step towards a greener, more sustainable tomorrow.
                        </h6>
                        <h5 className='text-center mt-3'>Join us in driving positive change for our planet, one electric vehicle at a time.</h5>

                    </div>
                    <div className='text-center'>
                        <Link className="btn btn-lg btnApply">Apply Today</Link>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
