import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { FaArrowLeft } from "react-icons/fa";
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
export default function ErrorPage() {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <>
        <Navbar />
            <div className="ErrorPage">
                <div className="container">
                    <div className="row text-center">
                        <h3>404</h3>
                        <h4 className='mt-3'>Oops! Page not found</h4>
                        <h6 className='mt-3'>Something went wrong. It’s look that your requested could not be <br></br> found. It's look like the link is broken or the page is removed.</h6>
                        <div className="box">
                            <Link className="btn btn-lg btnApply me-3"><FaArrowLeft />&nbsp;Go Back</Link>
                            <Link className="btn btn-lg btnApply2" to={{pathname: "/",}} onClick={scrollToTop}>Home</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
