import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import credgreenlogo from '../../Assets/credgreen-logo.png'
import '../../css/Footer.scss'
import phoneicon from '../../Assets/phoneicon.png';
import appstoreicon from '../../Assets/appstoreicon.png';
import playstoreicon from '../../Assets/playstoreicon.png';
import { FaFacebookF, FaTwitter, FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
export default function Footer() {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    window.scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            sectionElement.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };

    function scrollToSection(sectionId) {

        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }
    return (
        <>
            <footer className="Footer circle-shape-footer">
                <div className="subcontent  circle-shape-footer2">
                    <div className="container pt-3">
                        <div className="f-items default-padding pt-5">
                            <div className="row rowitem">
                                <div className="col-xl-3 col-lg-4 col-md-6 mt-4">
                                    <div className="f-item about">
                                        <Link className="nav-link ps-0" to={{ pathname: "/" }} onClick={scrollToTop}><img src={credgreenlogo} alt="logo" width={'70%'} /></Link>
                                        <div className="address">
                                            <ul>
                                                <li>
                                                    <h4>Join us in driving positive change for our planet, one electric vehicle at a time.</h4>
                                                </li>
                                                <li>
                                                    <h5>
                                                        <img src={phoneicon} width={'7%'} alt="phoneicon" /> &nbsp;
                                                        <a href="tel:+012 (345) 678 99">+012 (345) 678 99</a>
                                                    </h5>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 mt-4 links">
                                    <div className="f-item link">
                                        <h4 className="widget-title">Resources</h4>
                                        <ul>
                                            <li>
                                                <Link className="nav-link ps-0" to={{
                                                    pathname: "/",
                                                }} onClick={scrollToTop}>Home</Link>
                                            </li>
                                            <li>
                                                <Link className="nav-link ps-0" to={{
                                                    pathname: "/aboutus",
                                                }} onClick={scrollToTop}>About Us</Link>

                                            </li>
                                            <li>
                                                <Link className="nav-link ps-0" to={{
                                                    pathname: "/contactus",
                                                }} onClick={scrollToTop}>Contact Us</Link>

                                            </li>
                                            <li>
                                                <Link className="nav-link ps-0" to={{
                                                    pathname: "/FAQ",
                                                }} onClick={scrollToTop}>FAQ</Link>

                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 mt-4">
                                    <div className="f-item URLS">
                                        <h4 className="widget-title">Download From:</h4>
                                        <div className="row mt-4">
                                            <div className="buttons">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="storeicon">
                                                            <a href="" target="_blank" className="buttonapplynow">
                                                                <img src={appstoreicon} width={'25%'} alt="appstore_icon" />&nbsp; App Store
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="storeicon">
                                                            <a href="" target="_blank" className="buttonapplynow">
                                                                <img src={playstoreicon} width={'25%'} alt="playstore_icon" />&nbsp; Play Store
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="widget-title mt-4">Follow Us On</h4>
                                        <div className="row mt-2">
                                            <div className="buttonlinks">
                                                <a href='' className="circlelink facebook-link">
                                                    <span><FaFacebookF /></span>
                                                </a>
                                                <a href='' className="circlelink twitter-link">
                                                    <span><FaTwitter /></span>
                                                </a>
                                                <a href='' className="circlelink youtube-link">
                                                    <span><FaYoutube /></span>
                                                </a>
                                                <a href='' className="circlelink linkdin-link">
                                                    <span><FaLinkedinIn /></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom mt-3">
                        <div className="container">
                            <div className="row">

                                <div className="col text-center">
                                    <h5 className='f-b' >copyright © CredGreen </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
