import React, { useState, useEffect, useRef } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import '../../css/HomePage.scss'
import "animate.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Aboutsectionimg from '../../Assets/Aboutsection-img.png';
import Ourservicessectionimg from '../../Assets/Ourservicessection-img.png';
import sectivesectioncardicon from '../../Assets/sectivesectioncard-icon.png';
import ourSolutionsectionimg from '../../Assets/ourSolutionsection-img.png';
import downloadApplication from '../../Assets/downloadApplication.png';
import applicationsubmission from '../../Assets/applicationsubmission.png';
import selectyouroption from '../../Assets/selectyouroption.png';
import documentation from '../../Assets/documentation.png';
import agreementsigning from '../../Assets/agreementsigning.png';
import confirmation from '../../Assets/confirmation.png';
import accessevbattery from '../../Assets/accessevbattery.png';

import benefitssectionimg1 from '../../Assets/benefits-section-img1.png';
import benefitssectionimg11 from '../../Assets/benefitssection-img1.png';
import benefitssectionimg22 from '../../Assets/benefitssection-img2.png';
import benefitssectionimg2 from '../../Assets/benefits-section-img2.png';
import benefitssectionimg33 from '../../Assets/benefitssection-img3.png';
import benefitssectionimg3 from '../../Assets/benefits-section-img3.png';
import benefitssectionimg44 from '../../Assets/benefitssection-img-4.png';
import benefitssectionimg4 from '../../Assets/benefits-section-img4.png';
import car from '../../Assets/car.png';
import ourbenefitssectionimg from '../../Assets/ourbenefitssection-img.png';
import bannerareaimg from '../../Assets/bannerarea-img.png';
import FAQ from './FAQ';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const HomePage = () => {
    const [openAccordion, setOpenAccordion] = useState(0);
    const [HoverAccordion, setHoverAccordion] = useState(null);
    const ourSolutionRef = useRef(null);
    const data = [
        {
            heading: 'Hassle-Free Leasing',
            text: 'Simplify your transition to electric vehicles with our leasing solutions.'
        },
        {
            heading: 'Accessible Options',
            text: 'Leasing programs designed for private individuals, businesses, and fleet managers.'
        },
        {
            heading: 'No Upfront Costs',
            text: 'Say goodbye to large upfront payments with our leasing arrangements.'
        },
        {
            heading: 'Flexible Terms',
            text: 'Choose leasing terms that fit your needs, whether short or long-term.'
        },
        {
            heading: 'Latest Technology',
            text: 'Lease the latest EV battery technology without the commitment of ownership.'
        },
        {
            heading: 'Maintenance Included',
            text: 'Some leasing packages may include maintenance and servicing for added convenience.'
        },
        {
            heading: 'Expert Support',
            text: ' Our team is here to guide you through the leasing process, ensuring a seamless experience from start to finish.'
        },
    ];
    const Benefitsdata = [
        {
            img: benefitssectionimg11,
            hoverimg: benefitssectionimg1,
            heading: 'Cost-Effective',
            text: 'Enjoy affordable financing or leasing options tailored to your budget.'
        },
        {
            img: benefitssectionimg22,
            hoverimg: benefitssectionimg2,
            heading: 'Lower Upfront Expenses',
            text: 'Say goodbye to large upfront costs typically associated with purchasing an EV battery.'
        },
        {
            img: benefitssectionimg33,
            hoverimg: benefitssectionimg3,
            heading: 'Flexibility',
            text: 'Choose from a range of financing or leasing options to suit your needs and preferences.'
        },
        {
            img: benefitssectionimg44,
            hoverimg: benefitssectionimg4,
            heading: 'Access to Latest Technology',
            text: 'Lease or finance the latest EV battery technology without the commitment of ownership.'
        },
        {
            img: benefitssectionimg44,
            hoverimg: benefitssectionimg4,
            heading: 'Hassle-Free Maintenance',
            text: 'Some packages may include maintenance and servicing for added convenience.'
        },
        {
            img: benefitssectionimg44,
            hoverimg: benefitssectionimg4,
            heading: 'Seamless Experience',
            text: ' Our experienced team is dedicated to providing personalised assistance throughout the process.'
        },
        // {
        //     heading: 'Sustainable Choice',
        //     text: 'Join the movement towards sustainable mobility and reduce your carbon footprint.'
        // },
        // {
        //     heading: 'Convenient Application Process',
        //     text: 'Apply online and start your journey towards electric mobility today.'
        // },
    ];

    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? null : id);
    };

    const HoverAccordionfn = (id) => {
        setHoverAccordion(HoverAccordion === id ? null : id);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setOpenAccordion((prevIndex) => {
                const nextIndex = prevIndex === null ? 0 : (prevIndex + 1) % data.length;
                return nextIndex;
            });
        }, 3000);

        return () => clearInterval(intervalId);
    }, [data.length]);


    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setOpenAccordion(0);
                }
            });
        }, options);

        if (ourSolutionRef.current) {
            observer.observe(ourSolutionRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <>
            <Navbar />
            <div className="banner-area auto-height">
                <div className="content-box">
                    <div className="row">
                        <div className="col-xl-7 col-lg-6 col-xs-12 info mt-5">
                            <h1>Welcome to <span className='color-blue'>Cred</span><span className='color-green'>Green</span>,<br></br> your premier destination for <br></br> EV battery financing and <br></br> leasing solutions!</h1>
                            <h6>Join us in driving positive change for our planet, one electric vehicle at a time.</h6>
                            <Link className="btn btn-lg btnApply">Apply Today</Link>
                            {/* <a href="" target="_blank" className="btn btn-lg border">
                                    Apply Today
                                </a> */}
                        </div>
                        <div className="col-xl-5 col-lg-6 col-xs-12 mt-5">
                            <div className="thumb-innner text-center">
                                <img src={bannerareaimg} width={'99%'} alt="banner_img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="Aboutus-section" id="aboutus">
                <div className="container">
                    <div className="row sectionheading">
                        <AnimationOnScroll animateIn="animate__zoomIn">
                            {" "}
                            <h1 className='color-blue text-center'>About Us</h1>
                        </AnimationOnScroll>
                        <h3>Elevate your <span className='color-green'>eco</span><span className='color-blue'>-drive</span> with <br></br> tailored EV financing.</h3>
                    </div>
                    <div className="row mt-4">
                        <div className="col-lg-6 col-sm-12 mt-2">
                            <div className="imgs text-center">
                                <img src={Aboutsectionimg} width={'80%'} alt="about_img" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 mt-2">
                            <div className="aboutsectiondata">
                                <h6 className='mt-3'>Empower your journey towards sustainable mobility with our innovative financing and leasing options tailored specifically for electric vehicle owners. At CredGreen, we understand the importance of accessible and affordable financing in accelerating the adoption of electric vehicles.</h6>
                                <h6 className='mt-3'>
                                    Experience the future of transportation today with CredGreen. Explore our range of financing options and take the first step towards a greener, more sustainable tomorrow. <Link className='viewmorelink' to={{ pathname: "/aboutus" }} onClick={scrollToTop}>View More</Link>
                                </h6>
                                <Link className="btn btn-lg btnApply">Apply Today</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="OurServices-section">
                <div className="container">
                    <div className="row sectionheading">
                        <AnimationOnScroll animateIn="animate__zoomIn">
                            {" "}
                            <h1 className='color-blue text-center'>Our Services</h1>
                        </AnimationOnScroll>
                        <h3>Financing Options</h3>
                        <h6>Say goodbye to the hefty upfront costs associated with purchasing an EV battery. Explore our flexible financing plans tailored for EV battery purchases. <span className='color-blue'>Ready to make the switch to electric?</span> <span className='color-grey'>Explore our financing options today and take the first step towards a greener, more sustainable future on the road.</span></h6>
                        <div className="imgss text-center mt-5">
                            <img src={Ourservicessectionimg} width={'65%'} alt="ourservices_img" />
                        </div>
                    </div>
                    <div className="row sectioncards mt-4">
                        <ServiceSectionCard img={sectivesectioncardicon} widths={'60%'} heading={'Flexible Financing Plans'} text={'Tailored options to suit your budget and lifestyle.'} />
                        <ServiceSectionCard img={sectivesectioncardicon} widths={'60%'} heading={'Competitive Rates'} text={'Enjoy affordable financing solutions with competitive interest rates.'} />
                        <ServiceSectionCard img={sectivesectioncardicon} widths={'60%'} heading={'Spread Costs'} text={'Say goodbye to hefty upfront expenses by spreading the cost over manageable monthly payments.'} />
                        <ServiceSectionCard img={sectivesectioncardicon} widths={'60%'} heading={'Customised Solutions'} text={'Options designed for private individuals, businesses, and fleet managers.'} />
                        <ServiceSectionCard img={sectivesectioncardicon} widths={'60%'} heading={'Range of Options'} text={'Choose from traditional loans to lease-to-own arrangements.'} />
                        <ServiceSectionCard img={sectivesectioncardicon} widths={'60%'} heading={'Expert Guidance'} text={'Our experienced team provides personalised assistance throughout the financing process.'} />
                        <ServiceSectionCard img={sectivesectioncardicon} widths={'60%'} heading={'Hassle-Free Experience'} text={'Simplify your experience; savor your electric ride stress-free.'} />
                        <ServiceSectionCard img={sectivesectioncardicon} widths={'60%'} heading={'Make the Switch'} text={'Discover eco-friendly financing options today for a greener, sustainable future on the road.'} />
                    </div>
                </div>
            </div>

            <div className="OurSolutions-section" ref={ourSolutionRef}>
                <div className="container">
                    <div className="row sectionheading">
                        <AnimationOnScroll animateIn="animate__zoomIn">
                            {" "}
                            <h1 className='color-blue text-center'>Our Solutions</h1>
                        </AnimationOnScroll>
                        <h3>Leasing Solutions</h3>
                        <h6 className='text-center'>Learn about our leasing programs for hassle-free access to EV battery technology</h6>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 mt-5">
                            <div className="imgss text-center">
                                <img src={ourSolutionsectionimg} width={'70%'} alt="oursolution_img" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 mt-5">
                            <div className="accordbox">
                                {data.map((item, index) => (
                                    <AccordionItem
                                        key={index}
                                        id={index}
                                        isOpen={openAccordion === index}
                                        toggleAccordion={toggleAccordion}
                                        item={item}
                                        data1={data}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="OurBenefits-section">
                <div className="container">
                    <div className="row sectionheading">
                        <AnimationOnScroll animateIn="animate__zoomIn">
                            {" "}
                            <h1 className='color-blue text-center'>Our Benefits</h1>
                        </AnimationOnScroll>
                        <h3>What Benefit Will You Get</h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 mt-5">
                            <div className="hoverablebox">
                                {Benefitsdata.map((item, index) => (
                                    <HoverableItem
                                        key={index}
                                        id={index}
                                        isOpen={HoverAccordion === index}
                                        HoverAccordionfn={HoverAccordionfn}
                                        item={item}
                                        data1={Benefitsdata}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 mt-5">
                            <div className="imgss text-center">
                                <img src={ourbenefitssectionimg} width={'70%'} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            <div className="Processection">
                <div className="container-lg">
                    <div className="row sectionheading">
                        <AnimationOnScroll animateIn="animate__zoomIn">
                            {" "}
                            <h1 className='color-blue text-center'>Application Process</h1>
                        </AnimationOnScroll>
                        <h3>How it Works ?</h3>
                        <h6 className='text-center'>Understand the process of obtaining financing or leasing for your EV battery.</h6>
                    </div>
                    <div className="cardss sectioncards mt-3">
                        <OwlCarousel className='owl-theme' loop dots={false} responsiveClass={true} responsive={
                            {
                                0: {
                                    items: 1,
                                    dots: true,
                                    nav: false,
                                    loop: true,
                                    autoplay: true,
                                    autoplayTimeout: 3000,
                                },
                                600: {
                                    items: 2,
                                    dots: true,
                                    nav: false,
                                    loop: true,
                                    autoplay: true,
                                    autoplayTimeout: 3000,
                                },
                                1000: {
                                    items: 3,
                                    dots: true,
                                    nav: false,
                                    loop: true,
                                    autoplay: true,
                                    autoplayTimeout: 3000,
                                },
                                1200: {
                                    items: 4.2,
                                    dots: true,
                                    nav: false,
                                    loop: true,
                                    autoplay: true,
                                    autoplayTimeout: 3000,
                                }
                            }
                        }>
                            <ServiceSectionCard cardclassname={'cardinside'} img={downloadApplication} widths={'45%'} heading={'1. Download Application'} text={' Download application from Google Play store or App Store.'} />
                            <ServiceSectionCard cardclassname={'cardinside'} img={applicationsubmission} widths={'80%'} heading={'2. Application Submission'} text={'Begin by filling out our simple and user-friendly application form directly within the app.'} />
                            <ServiceSectionCard cardclassname={'cardinside'} img={selectyouroption} widths={'80%'} heading={'3. Select Your Option'} text={'Explore the offers presented and choose the financing or leasing option that best fits your requirements.'} />
                            <ServiceSectionCard cardclassname={'cardinside'} img={documentation} widths={'80%'} heading={'4. Documentation'} text={'Complete any necessary documentation securely within the app, making the process efficient and convenient.'} />
                            <ServiceSectionCard cardclassname={'cardinside'} img={agreementsigning} widths={'75%'} heading={'5. Agreement Signing'} text={'Electronically sign the agreement directly within the app, eliminating the need for paperwork.'} />
                            <ServiceSectionCard cardclassname={'cardinside'} img={confirmation} widths={'75%'} heading={'6. Confirmation'} text={'Receive instant confirmation of your financing or leasing agreement, along with any additional details.'} />
                            <ServiceSectionCard cardclassname={'cardinside'} img={accessevbattery} widths={'75%'} heading={'7. Access Your EV Battery'} text={'With your agreement in place, you can now access the latest EV battery technology hassle-free.'} />
                            <ServiceSectionCard cardclassname={'cardinside'} img={car} widths={'75%'} heading={'8. Enjoy Your Electric Journey'} text={'Start enjoying the benefits of electric mobility with confidence, knowing you\'ve secured the right financing or leasing solution for your needs.'} />
                        </OwlCarousel>
                    </div>
                </div>
            </div>
            <div className="ApplyNow-section">
                <div className="container">
                    <div className="row text-center">
                        <div className='boxinside'>
                            <h3>Apply Now</h3>
                            <h6>Start your application process online to secure financing or leasing for your EV battery.</h6>
                            <Link className="btn btn-lg btnApply">Apply Today</Link>
                        </div>
                    </div>
                </div>
            </div>
            <FAQ />
            <Footer />
        </>
    )
}

export default HomePage;

function ServiceSectionCard({ cardclassname, img, widths, heading, text }) {
    return (
        <>
            <div className={cardclassname === 'cardinside' ? 'cardinside' : 'col-xl-3 col-lg-4 col-sm-6 col-xs-12'}>
                <div className="box">
                    <div className="subbox">
                        <img src={img} alt="icon" style={{ width: widths }} />
                    </div>
                    <h5>{heading}</h5>
                    <h6>{text}</h6>
                </div>
            </div>
        </>
    )
}

function AccordionItem({ id, isOpen, toggleAccordion, item }) {
    return (
        <div className={`accords ${isOpen ? 'openaccord' : ''}`}>
            <div className="heading">
                <div className="box" onClick={() => toggleAccordion(id)}>
                    <h5>{item.heading}</h5>
                </div>
                {isOpen && (
                    <div className="bottomtext">
                        <p>{item.text}</p>
                    </div>
                )}
            </div>
        </div>
    );
}
function HoverableItem({ id, isOpen, HoverAccordionfn, item }) {
    return (
        <div className={`accords ${isOpen ? 'openaccord' : ''}`} >
            <div className="heading" onMouseEnter={() => HoverAccordionfn(id)} onMouseLeave={() => HoverAccordionfn(null)}>
                <div className="box">
                    <h5><img src={isOpen ? item.hoverimg : item.img} width={'4%'} alt="" className='me-2' />{item.heading}</h5>
                </div>
                {isOpen && (
                    <div className="bottomtext">
                        <p>{item.text}</p>
                    </div>
                )}
            </div>
        </div>
    );
}
