import React from 'react'
import '../../css/HomePage.scss';
import { Link, NavLink } from 'react-router-dom'
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
export default function FAQPage() {
    return (
        <>
            <Navbar />
            <div className="FAQ-section faqpage">
                <div className="container">
                    <div className="row sectionheading">
                        {/* <h5 className='color-blue text-center'>FAQ</h5> */}
                        <h3>Any Questions? Look Here</h3>
                        <h6 className='text-center color-grey'>Find answers to commonly asked questions about EV battery financing and leasing</h6>
                    </div>

                    <div className="row">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        What is EV battery financing and leasing?
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        EV battery financing and leasing are financial options that allow individuals and businesses to acquire electric vehicle batteries through flexible payment plans without the need for a large upfront payment.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        How does EV battery financing work?
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        EV battery financing involves borrowing funds to purchase an electric vehicle battery and paying back the loan over time with interest. This option allows you to spread the cost of the battery over manageable monthly payments.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        What are the benefits of financing an EV battery?

                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Financing an EV battery enables you to enjoy the benefits of electric mobility without a significant upfront investment. It also allows you to conserve your capital for other expenses and may provide tax benefits or incentives.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        What is EV battery leasing?
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        EV battery leasing involves renting an electric vehicle battery for a specified period in exchange for monthly payments. At the end of the lease term, you may have the option to return the battery, renew the lease, or purchase the battery outright.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    What are the advantages of leasing an EV battery?
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                    Leasing an EV battery typically requires lower monthly payments compared to financing, as you are only paying for the use of the battery rather than ownership. Leasing also provides flexibility, as you can upgrade to newer battery technology at the end of the lease term.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                    How do I apply for EV battery financing or leasing?

                                    </button>
                                </h2>
                                <div id="collapsesix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                    You can apply for EV battery financing or leasing through our mobile app or website by filling out a simple application form. Our team will then review your application and provide personalised financing or leasing options based on your financial situation and preferences.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                                    What factors determine my eligibility for EV battery financing or leasing?

                                    </button>
                                </h2>
                                <div id="collapseseven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                    Eligibility for EV battery financing or leasing is typically based on factors such as credit history, income, and employment status. Our team will assess your eligibility during the application review process.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" aria-expanded="false" aria-controls="collapseeight">
                                    Can I pay off my EV battery financing early?

                                    </button>
                                </h2>
                                <div id="collapseeight" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                    Yes, many financing agreements allow you to pay off your EV battery financing early without incurring penalties. Early repayment can help you save on interest charges and own the battery outright sooner.

                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenine" aria-expanded="false" aria-controls="collapsenine">
                                    What happens if I want to end my EV battery lease early?

                                    </button>
                                </h2>
                                <div id="collapsenine" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                    Ending your EV battery lease early may incur fees or penalties, depending on the terms of your lease agreement. It's important to review the terms and conditions of your lease agreement carefully before making any decisions.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseten" aria-expanded="false" aria-controls="collapseten">
                                    What happens at the end of my EV battery financing or leasing term?


                                    </button>
                                </h2>
                                <div id="collapseten" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                    At the end of your EV battery financing term, you will own the battery outright. At the end of your lease term, you may have the option to return the battery, renew the lease, or purchase the battery outright, depending on the terms of your agreement.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
