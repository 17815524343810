import React, { useState } from 'react'
import '../../css/HomePage.scss';
import { Link, NavLink } from 'react-router-dom'
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { TfiEmail } from "react-icons/tfi";
import { BiPhoneCall } from "react-icons/bi";
import { FaFacebookF, FaTwitter, FaInstagram, FaArrowRight } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import locationicon from '../../Assets/locationicon.png'
import SuccessPopup from '../Pagecomponent/SuccessPopup';
import ErrorPage from '../Pagecomponent/ErrorPage';
export default function ContactUsPage() {
    const [popup, setpopup] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        contactMethod: 'email',
        subject: '',
        message: '',
        subscribe: false
    });
    const objectdata = {};
    const handlesubmit = () => {
        setpopup(true);
        console.log("fomr",formData)
    }
    const handleclose = () => {
        setpopup(false);
    }
    const changehandler = (e) => {
        const { name, value,type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        setFormData({
            ...formData,
            [name]: newValue,
          });
    }
    return (
        <>
            {
                popup == true ?
                    <SuccessPopup handleclose={handleclose} />
                    : ''}
            <Navbar />
            <div className="contactus-page">
                <div className="container">
                    <h5 className='text-center heading'>Get in touch with our team for personalised assistance and support.</h5>
                    <div className="box">
                        <div className="row boxinside">
                            <div className="col-xl-5 col-lg-6 leftbox">
                                <h4>Get in touch</h4>
                                <div className="contactinfo">
                                    <div className="info">
                                        <div className="icon">
                                            <span><TfiEmail /></span>
                                        </div>
                                        <div className="text">
                                            <h6>Email us</h6>
                                            <h5>info@Credgreen.com</h5>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <div className="icon">
                                            <span><BiPhoneCall /></span>
                                        </div>
                                        <div className="text">
                                            <h6>Phone number</h6>
                                            <h5>+1-202-555-0138</h5>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <div className="icon">
                                            <img src={locationicon} width={'60%'} alt="location_icon" />
                                        </div>
                                        <div className="text">
                                            <h5>901 N Pitt Str., Suite 170</h5>

                                            <h5>Alexandria, VA 22314, USA</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="links">
                                    <div className="heads">
                                        <div className="hrbox"></div>
                                        <h5>Connect with us:</h5>
                                    </div>
                                    <div className="buttonlinks mt-3">
                                        <a href='' className="squarelink facebook-link">
                                            <span><FaFacebookF /></span>
                                        </a>
                                        <a href='' className="squarelink twitter-link">
                                            <span><FaTwitter /></span>
                                        </a>
                                        <a href='' className="squarelink linkdin-link">
                                            <span><FaLinkedinIn /></span>
                                        </a>
                                        <a href='' className="squarelink instagram-link">
                                            <span><FaInstagram /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-6 rightbox">
                                <div className="form">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-12 col-md-6 col-xs-12 mt-3">
                                            <label for="name" className="form-label contactlabels">Name</label>
                                            <input type="name" className="form-control inputboxes paddingset" id="name" aria-describedby="Name"
                                                placeholder='Full Name' name='name' onChange={changehandler} value={formData.name} />
                                        </div>
                                        <div className="col-xl-6 col-lg-12 col-md-6 col-xs-12 mt-3">
                                            <label for="email" className="form-label contactlabels">Email</label>
                                            <input type="email" className="form-control inputboxes paddingset" id="email" aria-describedby="email"
                                                placeholder='Email Address' name='email' onChange={changehandler} value={formData.email} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-12 col-md-6 col-xs-12 mt-3">
                                            <label for="number" className="form-label contactlabels">Phone Number</label>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text numberLeftsidetext" id="basic-addon1">+91︱</span>
                                                <input type="number" className="form-control inputboxes paddingset borderleftnone" placeholder="" aria-label="number" aria-describedby="basic-addon1" name='phoneNumber' onChange={(e) => changehandler(e)} value={formData.phoneNumber} />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-12 col-md-6 col-xs-12 mt-3">
                                            <label for="email" className="form-label contactlabels">Preferred Contact Method</label>
                                            <select className="form-select paddingset" aria-label="Default select example" onChange={(e) => changehandler(e)} value={formData.contactMethod} name='contactMethod'>
                                                <option value="email">Email Address</option>
                                                <option value="phonenumber">Phone Number</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 mt-2">
                                            <label for="email" className="form-label contactlabels">Subject</label>
                                            <select className="form-select paddingset" aria-label="Default select example" onChange={(e) => changehandler(e)} value={formData.subject} name='subject'>
                                                <option selected>Select subject</option>
                                                <option value="email">Email Address</option>
                                                <option value="phonenumber">Phone Number</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 mt-3">
                                            <label for="email" className="form-label contactlabels">Message</label>
                                            <div className="form-floating">
                                                <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: "100px" }} onChange={(e) => changehandler(e)} name="message" value={formData.message}></textarea>
                                                <label for="floatingTextarea2">Tell us about your Doubts...</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="mb-3 form-check">
                                            <input type="checkbox" className="form-check-input checkboxinput" id="exampleCheck1" onClick={(e) => changehandler(e)} checked={formData.subscribe} name="subscribe"/>
                                            <label className="form-check-label contactlabels mt-2" for="exampleCheck1">Subscribe to marketing related mails from CredGreen.</label>
                                        </div>
                                        <div>
                                            <Link className="btn btn-lg btnApply" onClick={() => handlesubmit()}>Submit <FaArrowRight /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ApplyNow-section mt-4">
                <div className="container">
                    <div className="row text-center">
                        <div className='boxinside'>
                            <h3>Apply Now</h3>
                            <h6>Start your application process online to secure financing or leasing for your EV battery.</h6>
                            <Link className="btn btn-lg btnApply">Apply Today</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
