import { Link, NavLink } from 'react-router-dom'
import credgreenlogo from '../../Assets/credgreen-logo.png'
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import '../../css/Navbar.scss';
function Navbar() {

    let navRef = useRef(null);
    let navigate = useNavigate();
    const [screenSize, setScreenSize] = useState('small');
    useEffect(() => {
        let width = window.screen.width;
        if (width <= 991) {
            setScreenSize('small');
        } else {
            setScreenSize('large');
        }
    }, [])

    function navClickHandler() {
        if (screenSize === 'small') {
            if (navRef) {
                navRef.current.classList.toggle("opened")
            }
        }
    }
    window.scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            sectionElement.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };
    function scrollToSection(sectionId) {

        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <nav ref={navRef} className="navbar navbar-light navbar-expand-lg fixed-top">
            <div className="container">

                <Link className="navbar-brand" to={{ pathname: "/" }} onClick={scrollToTop}>
                    <img src={credgreenlogo} alt="Logo" width={'50%'} height="auto" />
                </Link>
                <button onClick={navClickHandler} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0" >
                        <li className="nav-item">
                            <Link className='nav-link'  to={{ pathname: "/" }} onClick={scrollToTop} aria-current="page">Home</Link>
                        </li>
                        
                        <li className="nav-item">
                            <Link className="nav-link" to={{ pathname: "/aboutus" }} onClick={scrollToTop}>About Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link"to={{
                                pathname: "/contactus",
                            }} onClick={scrollToTop}>Contact Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link ps-0" to={{
                                pathname: "/FAQ",
                            }} onClick={scrollToTop}>FAQ</Link>
                        </li>
                    </ul>
                    <button className='btn bg-light pt-2 pb-2'>Apply Now</button>
                    {/* <a href="https://play.google.com/store/apps/details?id=com.credfin" target="_blank" className="btn bg-light pt-2 pb-2">
                        Apply Now
                    </a> */}
                </div>
            </div>
        </nav>
    )
}

export default Navbar