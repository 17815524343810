import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import "animate.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function FAQ() {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <>
            <div className="FAQ-section">
                <div className="container">
                    <div className="row sectionheading">
                    <AnimationOnScroll animateIn="animate__zoomIn">
                            {" "}
                        <h1 className='color-blue text-center'>FAQ</h1>
                        </AnimationOnScroll>
                        <h3>Any Questions? Look Here</h3>
                        <h6 className='text-center'>Find answers to commonly asked questions about EV battery financing and leasing</h6>
                    </div>

                    <div className="row">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        What is EV battery financing and leasing?
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        EV battery financing and leasing are financial options that allow individuals and businesses to acquire electric vehicle batteries through flexible payment plans without the need for a large upfront payment.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        How does EV battery financing work?
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        EV battery financing involves borrowing funds to purchase an electric vehicle battery and paying back the loan over time with interest. This option allows you to spread the cost of the battery over manageable monthly payments.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        What are the benefits of financing an EV battery?

                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Financing an EV battery enables you to enjoy the benefits of electric mobility without a significant upfront investment. It also allows you to conserve your capital for other expenses and may provide tax benefits or incentives.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        What is EV battery leasing?
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        EV battery leasing involves renting an electric vehicle battery for a specified period in exchange for monthly payments. At the end of the lease term, you may have the option to return the battery, renew the lease, or purchase the battery outright.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='text-center mt-4'>
                        <Link className="btn btn-lg btnApply2" to={{
                                pathname: "/FAQ",
                            }} onClick={scrollToTop}>View More Questions</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
